import React from "react";
import SEO from "../components/seo";
import { Div, Container } from "@components/Markup";
import { Title } from "@components/Typography";

const NotFoundPage = () => (
  <React.Fragment>
    <SEO title="404: Not found" />
    <Div pt="lg" pb="lg">
      <Container>
        <Title size="lg" mb="md">
          404: Page not found
        </Title>
        <Title size="sm">
          The page you requested cannot be found. Try going to the homepage.
        </Title>
      </Container>
    </Div>
  </React.Fragment>
);

export default NotFoundPage;
